:root {
  --numDays: 7;
  --numHours: 35;
  --timeHeight: 60px;
  --calBgColor: #fff1f8;
  --eventBorderColor: #f2d3d8;
  --eventColor1: #ffd6d1;
  --eventColor2: #fafaa3;
  --eventColor3: #e2f8ff;
  --eventColor4: #d1ffe6;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

@font-face {
  font-family: 'Albany';
  src: url('fuentes/AlbanyStd.ttf') format('truetype')
}

body {
  margin: 0;
  // font-family: 'Roboto Condensed', sans-serif;
  font-family: 'Albany', sans-serif;
  /** COMIENZA CÓDIGO RESET*/
  overflow-x: hidden;
  overflow-y: auto;
}

/* -- usable codes start -- 

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  text-decoration: none;
  list-style: none;
}
*/
h1 {
  font-size: 2em;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 150;
}

h3 {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 500;
}

ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

/*
img {
    width: 100%
}
*/
.anim04c {
  -webkit-transition: all .4s cubic-bezier(.5, .35, .15, 1.4);
  transition: all .4s cubic-bezier(.5, .35, .15, 1.4);
}

/*
html--,
body {

    width: 100%;
    height: 100%;
}*/

/*----- finaliza reset CSS*/

.loader-list-videos-home {
  width: 100px;
  left: calc(50% - 100px);
  position: absolute;
}
.header-baja-home{
  width: 100%;
  height:100px;
  background-color: black;
  background-image: url("images/logo_cabeza_white.png");
  background-position:top left;
  background-size:30%;
  background-repeat: no-repeat;
}
@media screen and (min-device-width:250px) and (max-device-width:912px) {
  .content-baja-home{
    position: relative;
    background: #203b12;
    width:100%;
    height:100%;
    min-height: 2000px;
    & .text-baja-home{
      position: absolute;
      top:50%;
      left:50%;
      border-radius: 45px;
      -ms-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      box-shadow: #15171c 40px 40px;
      width:45%;
      margin: auto;
      height:350px;
      line-height: 350px;
      background-color: #d6bb0d;
      color:#203b12;
      font-size: large;
      text-align: center;
      & p{
      text-align: center;
      vertical-align: middle;
      line-height: 1.5;
      display: inline-block;
      padding:1em;
      }
    }
  }
  
}
@media screen and (min-device-width:1000px) {
  .content-baja-home{
    position: relative;
    background: #203b12;
    width:100%;
    height:100%;
    min-height: 700px;
    & .text-baja-home{
      position: absolute;
      top:50%;
      left:50%;
      border-radius: 45px;
      -ms-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      box-shadow: #15171c 40px 40px;
      width:45%;
      margin: auto;
      height:350px;
      line-height: 350px;
      background-color: #d6bb0d;
      color:#203b12;
      font-size: large;
      text-align: center;
      & p{
      text-align: center;
      vertical-align: middle;
      line-height: 1.5;
      display: inline-block;
      padding:1em;
      }
    }
  }
}
/*
Estilo de footer
*/

.footer-site {
  max-height: 350px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr .2fr .5fr;
  justify-content: left;
  background-image: url('/images/head_.svg');
  background-repeat: no-repeat;
  background-size: 45%;
  background-position: top center;
}

.footer-site div.empty-container {
  height: 100px;
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;

}

.footer-site-black {
  background-color: gray;

}


.footer-list-black {
  background-color: red;
}

.footer-list-white {
  list-style: none;
  text-align: left;
  color: white;
  width: 100%;
  height: 100%;


}

.footer-list-white li {
  cursor: pointer;

  & a {
    text-decoration: none;
    color: white;
  }
}

.logo-interneta-footer {
  width: 400px;
  margin: 6em 0;
}

.container-list-red {
  margin: 0 2em;
  width: 100%;
  height: 140px;
  display: flex;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 3;
  justify-content: space-between;
}

.logos-sociales-footer {
  display: grid;
  width: 100%;
  padding-bottom: 15px;
  height: 40px;
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 3;
  grid-template-columns: 40% 10% 10% 40%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  margin: auto;

  & .spline-logos-sociales-footer-1 {
    height: 50px;
    width: 100%;
    background-image: url('/images/cable_inicio_2.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    grid-column: 1;
  }

  & .spline-logos-sociales-footer-2 {
    height: 50px;
    width: 100%;
    background-image: url('/images/cable_inicio_1.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    grid-column: 4;
  }

  & img {
    width: 20px;
    vertical-align: bottom;
  }

  & a {
    text-decoration: none;
    color: white;
    font-size: x-small;
    display: block;
  }
}

.logos-sociales-footer a:nth-child(1) {
  grid-column: 2;
}

.logos-sociales-footer a:nth-child(2) {
  grid-column: 2;
}

h3#footer-vinculos {
  background-image: url('/images/vga_1.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 10%;
  padding-left: 15%;
  padding-bottom:.25em;
}

h3#footer-eventos {
  background-image: url('/images/vga_2.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 10%;
  padding-left: 15%;
  padding-bottom:.25em;
}

h3#footer-categorias {
  background-image: url('/images/vga_5.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 10%;
  padding-left: 15%;
  padding-bottom:.25em;
}

h3#footer-salaschat {
  background-image: url('/images/vga_4.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 10%;
  padding-left: 15%;
  padding-bottom:.25em;
}

.logos-patrocinadores-footer {
  padding-top: 0.5em;
  display: grid;
  width: 100%;
  gap: 2em;
  grid-column: 2;
  grid-row: 4;
  grid-auto-flow: column;
  justify-items: center;

  & div {
    width: 200px;
  }

  & .logo-imcine {
    background-image: url('/images/imcine_blanco.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  & .logo-focine {
    background-image: url('/images/focine_blanco.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  & .logo-sec-cultura {
    background-image: url('/images/secretaria_cultura_w.png');
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
  }

}

.logo-interneta-footer img {
  width: 100%;
}

.categoria-home {
  margin: 1em 2.82% 1em 0;
}

.category-images-green {
  background-color: #2B9230;
  position: relative;
  margin: 1em 1.3% 1em 0;
}

.category-images-green div.inner-hover-categoria {
  position: absolute;
  width: 96%;
  height: 95%;
  z-index: 9999;
  background-color: white;
  opacity: .5;
  display: none;

  & span {
    display: inline-block;
    vertical-align: middle;
    padding-top: 32%;
    font-weight: bold;
    font-size: xx-large;
    display: inline-block;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
}



@media screen and (max-width:1170) {

  .logos-sociales-footer a {
    text-decoration: none;
    color: white;
    grid-row: 2;
    font-size: x-small;
  }

  .logos-sociales-footer div.legend-logos-sociales {
    color: white;
    display: none;
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 2;

    & p {
      width: 100%;
      text-align: center;
      font-size: x-small;
    }
  }
}

@media screen and (max-width:1000px) {
  .logo-interneta-footer img:nth-child(1) {
    width: 50%;
    grid-column: 2;
  }
}


